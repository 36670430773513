import Analytic from './Analytic';
import Opportunities from './Opportunities';
import FirstBlock from './FirstBlock';

const InfluencerPage = () => {
  return (
    <>
      <FirstBlock />
      <Opportunities />
      <Analytic />
    </>
  );
};

export default InfluencerPage;
