import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import StyledH2 from '../UI/StyledH2';
import member1 from '../../assets/images/member1.jpg';
import member2 from '../../assets/images/member2.jpg';
import member3 from '../../assets/images/member3.jpg';
import member4 from '../../assets/images/member4.jpg';
import member5 from '../../assets/images/member5.jpg';

const StyledContentMembers = styled(StyledContent)({
  flexDirection: 'column'
});

const StyledPhotoWrapper = styled.div({
  display: 'flex',
  gap: '32.5px',
  flexWrap: 'wrap',
  justifyContent: 'space-around'
});

const StyledImgContainer = styled.div({
  display: 'flex',
  flexDirection: 'column'
});

const StyledImg = styled.img({
  borderRadius: '50%',
  width: '230px',
  height: '230px',
  objectFit: 'cover'
});

const StyledName = styled.h3({
  fontFamily: 'Gilroy',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '35.79px',
  textAlign: 'center',
  color: '#2F2F2F',
  marginTop: '7px',
  '@media (max-width: 600px)': {
    fontSize: '16px',
    lineHeight: '18.79px'
  }
});

const StyledPosition = styled.h4({
  fontFamily: 'Gilroy',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '35.79px',
  textAlign: 'center',
  color: '#2F2F2F',
  marginTop: '7px',
  '@media (max-width: 600px)': {
    fontSize: '16px',
    lineHeight: '18.79px'
  }
});

const TeamMembers = () => {
  return (
    <StyledContentMembers>
      <StyledH2 style={{ margin: '132px auto 30px' }}>Team</StyledH2>
      <StyledPhotoWrapper>
        <StyledImgContainer>
          <StyledImg src={member1} />
          <StyledName>Yevhenii Lytvynenko</StyledName>
          <StyledPosition>CEO</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member2} />
          <StyledName>Nikolay Vovk</StyledName>
          <StyledPosition>CDO</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member3} />
          <StyledName>Ivan Tymoshenko</StyledName>
          <StyledPosition>CTO</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member4} />
          <StyledName>Denys Fakhriian</StyledName>
          <StyledPosition>CPOO</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member5} />
          <StyledName>Max Kovalenko</StyledName>
          <StyledPosition>CIO</StyledPosition>
        </StyledImgContainer>
      </StyledPhotoWrapper>
    </StyledContentMembers>
  );
};

export default TeamMembers;
