import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import Monetezation1 from '../svg/Monetezation1';
import Monetezation2 from '../svg/Monetezation2';
import Monetezation3 from '../svg/Monetezation3';
import StyledParagraph from '../UI/StyledParagraph';
import MonetizationCircle from '../svg/MonetizationCircle';
import StyledBold from '../UI/StyledBold';

const MonetizationWrapper = styled.div({
  position: 'relative',
  overflow: 'hidden',
  marginTop: '80px'
});

const MonetizationContent = styled(StyledContent)({
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '0px 30px'
  }
});

const StyledH3 = styled.h3({
  fontFamily: 'Gilroy',
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '47.72px',
  textAlign: 'center'
});

const CardsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  margin: '29px 0 37px',
  gap: '25px',
  flexWrap: 'wrap',
  '@media (max-width: 1273px)': {
    justifyContent: 'center'
  }
});

const Card = styled.div({
  borderRadius: '10px',
  padding: '20px',
  background: '#F5F5F5',
  border: '1px solid #C3C3C3',
  display: 'flex',
  flexDirection: 'column',
  width: '360px',
  '> p': {
    color: '#2F2F2F',
    marginTop: '10px'
  },
  '@media (max-width: 1273px)': {
    marginBottom: '0px !important'
  },
  '@media (max-width: 600px)': {
    width: 'auto',
    height: 'auto',
    '> p': {
      textAlign: 'left'
    }
  }
});

const MonetizationCircleSvg = styled.div({
  position: 'absolute',
  zIndex: -1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%)'
});

const MonetizationOptions = () => {
  return (
    <MonetizationWrapper>
      <MonetizationCircleSvg>
        <MonetizationCircle />
      </MonetizationCircleSvg>
      <MonetizationContent>
        <StyledH3>Monetization options</StyledH3>
        <CardsWrapper>
          <Card>
            <Monetezation3 />
            <StyledBold style={{ marginTop: '10px' }}>Straightforward Revenue Model:</StyledBold>
            <StyledParagraph style={{ fontWeight: 500, fontSize: '16px' }}>
              We apply a 20% commission on each blogger`s subscription revenue. This simple and
              transparent model allows us to generate income directly from the success of our
              content creators, aligning our interests with theirs as the platform grows.
            </StyledParagraph>
            <StyledParagraph style={{ fontWeight: 500, fontSize: '16px', color: '#009E00' }}>
              *monthly subscription
            </StyledParagraph>
          </Card>
          <Card>
            <Monetezation1 />
            <StyledBold style={{ marginTop: '10px' }}>
              Multifaceted Monetization Ecosystem:
            </StyledBold>
            <StyledParagraph style={{ fontWeight: 500, fontSize: '16px' }}>
              Beyond our blog with paid placement opportunities, we`re expanding our offering to
              include: A digital magazine featuring exclusive content An integrated e-commerce
              platform allowing bloggers to sell their products with our 4% commission. This
              comprehensive ecosystem creates multiple revenue streams and enhances the platform`s
              value for all participants.
            </StyledParagraph>
            <StyledParagraph style={{ fontWeight: 500, fontSize: '16px', color: '#009E00' }}>
              *Diverse Monetization Ecosystem
            </StyledParagraph>
          </Card>
          <Card>
            <Monetezation2 />
            <StyledParagraph style={{ fontWeight: 500, fontSize: '16px' }}>
              Our cutting-edge advertising system offers unprecedented accuracy in ad targeting
              across multiple placements: Main application Digital and print magazine E-commerce
              platform Our multi-channel approach maximizes ROI and engagement, offering a
              comprehensive solution for brands looking to connect with specific audience segments.
            </StyledParagraph>
          </Card>
        </CardsWrapper>
      </MonetizationContent>
    </MonetizationWrapper>
  );
};

export default MonetizationOptions;
