import About from './About';
import Embrace from './Embrace';
import Functionality from './Functionality';
import TeamMembers from './TeamMembers';

const UserPage = () => {
  return (
    <>
      <Embrace />
      <About />
      <Functionality />
      <TeamMembers />
    </>
  );
};

export default UserPage;
