import styled from 'styled-components';

const StyledFooter = styled.footer({
  padding: '30px 12px',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.02em',
  marginTop: '40px'
});

const StyledText = styled.span({
  fontFamily: 'Gilroy',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  textAlign: 'center',
  color: '#131313',
  opacity: '40%',
  display: 'block',
  margin: '10px auto 0',
  maxWidth: '780px'
});

const StyledLink = styled.span({
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  textAlign: 'center',
  color: '#252834',
  display: 'block',
  margin: '10px auto 0',
  maxWidth: '780px',
  '> a': {
    color: '#252834'
  }
});

const Footer = () => {
  return (
    <StyledFooter>
      <StyledLink>
        © 2024 Create Inc.{' '}
        <a
          href="https://docs.google.com/document/d/1B-mxbeB6FZJUd-ZKrfgh5PUdtKuwC9_XAB12WCwDA1E/edit?usp=sharing"
          target="_blank"
          rel="noreferrer">
          Privacy
        </a>{' '}
        /{' '}
        <a
          href="https://docs.google.com/document/d/1coM3rq2PU-TqX9IzyvFpuIo26D1VX29jcuL7XviXTbk/edit?usp=sharing"
          target="_blank"
          rel="noreferrer">
          Terms
        </a>
      </StyledLink>
      <StyledText>
        All faces of famous people are used for informational purposes only and for a full view of
        the scope of the product. photos will be replaced in the near future
      </StyledText>
    </StyledFooter>
  );
};

export default Footer;
