import StyledContent from '../UI/StyledContent';
import investorImg from '../../assets/images/investor.webp';
import styled from 'styled-components';
import StyledH2 from '../UI/StyledH2';
import StyledParagraph from '../UI/StyledParagraph';
import InvestorSvg1 from '../svg/InvestorSvg1';
import InvestorSvg2 from '../svg/InvestorSvg2';

const StyledWrapper = styled.div({
  position: 'relative',
  paddingTop: '130px',
  '@media (max-width: 900px)': {
    paddingTop: '49px'
  }
});

const BlockWrapper = styled(StyledContent)({
  '@media (max-width: 900px)': {
    padding: '0px 12px',
    flexWrap: 'wrap-reverse',
    paddingTop: '24px'
  }
});

const BlockContent = styled.div({
  textAlign: 'left',
  flexBasis: '50%',
  '> p': {
    marginTop: '18px',
    color: '#2F2F2F'
  },
  '> h2': {
    textAlign: 'left'
  },
  '@media (max-width: 900px)': {
    flexBasis: '100%',
    '> img': {
      marginTop: '24px',
      width: '100%'
    },
    '> h2': {
      textAlign: 'center'
    }
  }
});

const StyledImg = styled.img({
  maxWidth: '480px'
});

const StyledInvestor1 = styled.div({
  position: 'absolute',
  top: 0,
  zIndex: -1,
  maxWidth: '10vw',
  '> svg': {
    width: '100%'
  }
});

const StyledInvestor2 = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: -1,
  maxWidth: '20vw',
  '> svg': {
    width: '100%'
  }
});

const FirstBlock = () => {
  return (
    <StyledWrapper>
      <StyledInvestor1>
        <InvestorSvg1 />
      </StyledInvestor1>
      <StyledInvestor2>
        <InvestorSvg2 />
      </StyledInvestor2>
      <BlockWrapper>
        <BlockContent>
          <StyledImg src={investorImg} />
        </BlockContent>
        <BlockContent>
          <StyledH2>Why Invest in Us?</StyledH2>
          <StyledParagraph>
            Our platform offers a highly scalable and profitable business model by leveraging the
            power of influencers and their audiences. Here’s why we stand out:
          </StyledParagraph>
          <StyledParagraph>
            1. Monetizing Influencer Audiences.
            <br /> We provide influencers with an additional revenue stream through a
            subscription-based model. By transferring their audiences from other social networks to
            our platform, we unlock new monetization opportunities while creating a valuable
            ecosystem for advertisers.
          </StyledParagraph>
          <StyledParagraph>
            2. High-Value Advertising Opportunities.
            <br />
            With access to highly relevant and segmented layers of audience data, our platform
            becomes a prime space for targeted advertising campaigns. This creates a dual revenue
            stream: subscriptions and ad placements.
          </StyledParagraph>
          <StyledParagraph>
            3. Strong User Retention.
            <br />
            Habits are part of daily life, making our platform inherently sticky. Daily engagement
            with unique influencer content, task completion metrics, and thematic articles ensures
            high retention rates and long-term user loyalty.
          </StyledParagraph>
        </BlockContent>
      </BlockWrapper>
    </StyledWrapper>
  );
};

export default FirstBlock;
