import styled from 'styled-components';

const StyledBold = styled.span({
  fontFamily: 'Gilroy',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '23.2px',
  textAlign: 'left',
  color: '#252834'
});

export default StyledBold;
