import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import StyledH2 from '../UI/StyledH2';
import functionality1 from '../../assets/images/functionality1.webp';
import functionality2 from '../../assets/images/functionality2.webp';
import functionality3 from '../../assets/images/functionality3.webp';
import functionality4 from '../../assets/images/functionality4.webp';
import LeftStars from '../svg/LeftStars';
import RightStars from '../svg/RightStars';
import Blocks from '../svg/Blocks';

const StyledParagraph = styled.p({
  fontFamily: 'Gilroy',
  fontSize: '26px',
  fontWeight: 500,
  lineHeight: '30.6px',
  textAlign: 'center',
  color: '#2F2F2F',
  '@media (max-width: 600px)': {
    fontSize: '18px'
  }
});

const StyledInfoBlock = styled.div({
  padding: '20px 30px',
  borderRadius: '20px',
  background: '#F5F5F5',
  fontFamily: 'Gilroy',
  fontSize: '20px',
  fontWeight: 600,
  lineHeight: '23.86px',
  textAlign: 'left',
  maxWidth: '547px',
  display: 'flex',
  alignItems: 'flex-start',
  '> svg': {
    marginRight: '20px'
  },
  '@media (max-width: 600px)': {
    fontSize: '16px',
    textAlign: 'center',
    '> svg': {
      minWidth: '30px',
      height: '30px'
    }
  }
});

const ImageWrapper = styled.div({
  width: '100%',
  display: 'flex',
  maxWidth: '100%',
  justifyContent: 'space-around',
  marginTop: '60px',
  flexWrap: 'wrap'
});

const ImageContent = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '20px',
  '@media (max-width: 600px)': {
    flexWrap: 'nowrap',
    overflowY: 'scroll'
  }
});

const StyledImg = styled.img({
  maxWidth: '320px',
  '@media (max-width: 600px)': {
    width: '260px',
    minWidth: '260px'
  }
});

const StyledWrapper = styled.div({
  position: 'relative',
  '@media (max-width: 600px)': {
    padding: '12px'
  }
});

const StyledLeftStars = styled.div({
  position: 'absolute',
  zIndex: '-1',
  left: '52px'
});

const StyledRightStars = styled.div({
  position: 'absolute',
  zIndex: '-1',
  right: '31px'
});

const Functionality = () => {
  return (
    <StyledWrapper>
      <StyledLeftStars>
        <LeftStars />
      </StyledLeftStars>
      <StyledRightStars>
        <RightStars />
      </StyledRightStars>
      <StyledContent style={{ flexDirection: 'column', marginTop: '90px' }}>
        <StyledH2 style={{ maxWidth: '770px' }}>Unlock Your Potential with Insider Access</StyledH2>
        <StyledParagraph style={{ marginTop: '20px' }}>
          Our platform brings you closer to your role models, offering exclusive insights into their
          daily routines, success strategies, and hidden inspirations.
        </StyledParagraph>
        <StyledInfoBlock style={{ marginTop: '32px' }}>
          <Blocks />
          You will also be able to see unique content, and communicate with influencers in the app.
        </StyledInfoBlock>
        <ImageWrapper>
          <ImageContent>
            <StyledImg src={functionality1} />
            <StyledImg src={functionality2} />
            <StyledImg src={functionality3} />
            <StyledImg src={functionality4} />
          </ImageContent>
        </ImageWrapper>
      </StyledContent>
    </StyledWrapper>
  );
};

export default Functionality;
