/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import OpportunitiesSvg from '../svg/OpportunitiesSvg';
import StyledH5 from '../UI/StyledH5';
import StyledParagraph from '../UI/StyledParagraph';
import opportunitiesImg from '../../assets/images/opportunities.webp';
import mobileOpportunitiesImg from '../../assets/images/mobileOpportunites.webp';
import Tora from '../svg/Tora';
import StyledBold from '../UI/StyledBold';

const StyledOpportunitiesContent = styled.div({
  background: 'linear-gradient(248.33deg, #F8FCFF 35.78%, #FCFFED 98.36%)',
  position: 'relative',
  overflow: 'hidden'
});

const StyledWrapper = styled(StyledContent)({
  zIndex: 2,
  position: 'relative',
  '@media (max-width: 900px)': {
    flexWrap: 'wrap-reverse',
    padding: '30px',
    paddingBottom: '0px'
  }
});

const StyledBlock = styled.div({
  flexBasis: '50%',
  padding: '12px',
  '> img': {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  '> p': {
    color: '#2F2F2F',
    margin: '1ch 0px'
  },
  '@media (max-width: 900px)': {
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});

const StyledTora = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  transform: 'translate(-20%, 20%)',
  zIndex: 0,
  '@media (max-width: 900px)': {
    display: 'none'
  }
});

const StyledImg = styled.img({});

const Opportunities = () => {
  const isMobile = window.innerWidth <= 900;

  return (
    <StyledOpportunitiesContent>
      <StyledTora>
        <Tora />
      </StyledTora>
      <StyledWrapper>
        <StyledBlock style={{ paddingBottom: 0 }}>
          <StyledImg src={isMobile ? mobileOpportunitiesImg : opportunitiesImg} />
        </StyledBlock>
        <StyledBlock>
          <OpportunitiesSvg />
          <StyledH5 style={{ margin: '12px 0px', lineHeight: 1 }}>
            Elevate Your Influence, Amplify Your Income
          </StyledH5>
          <StyledParagraph>
            <StyledBold>Share Your Success Blueprint:</StyledBold> Give your fans exclusive access
            to the routines and habits that fuel your success.
          </StyledParagraph>
          <StyledParagraph>
            <StyledBold>Monetize Your Lifestyle:</StyledBold> Transform your daily practices into a
            lucrative income stream.
          </StyledParagraph>
          <StyledParagraph>
            <StyledBold>Seamless Content Sharing:</StyledBold> Easily upload photos, videos, and
            text about your daily habits and insider tips.
          </StyledParagraph>
          <StyledParagraph>
            Join the Future of Influencer Monetization. Don't just inspire – profit from your
            influence. Our platform bridges the gap between your lifestyle and your income
            potential.
          </StyledParagraph>
          <StyledParagraph>
            Ready to transform your habits into a thriving business? Join us today and start
            monetizing the very essence of your success!
          </StyledParagraph>
        </StyledBlock>
      </StyledWrapper>
    </StyledOpportunitiesContent>
  );
};

export default Opportunities;
