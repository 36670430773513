import TeamMembers from '../UserPage/TeamMembers';
import FirstBlock from './FirstBlock';
import MonetizationOptions from './MonetizationOptions';

const InvestorPage = () => {
  return (
    <>
      <FirstBlock />
      <MonetizationOptions />
      <TeamMembers />
    </>
  );
};

export default InvestorPage;
